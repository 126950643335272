<template>
  <v-app>

    <v-app-bar v-if="auth_store.logged" color="primary" height="100px">
      <v-app-bar-nav-icon @click.stop="collection_store.drawer = !collection_store.drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>Kagami the Gathering</v-app-bar-title>
      <div>{{auth_store.pseudo}}</div>

      <!--
        <v-btn icon="mdi-cog"></v-btn>
      -->
      <v-btn icon="mdi-logout" @click="auth_store.logout()"></v-btn>
    </v-app-bar>

    <NavigationDrawer
      style="margin-top:50px"
      v-if="auth_store.logged"
    />

    <v-main style="margin-top:50px">
      <v-alert
        class="general-alert"
        v-model="collection_store.alert.on"
        closable close-label="Close Alert"
        density="comforable" 
        :type="collection_store.alert.type"
      >
        {{collection_store.alert.msg}}
      </v-alert>


      <router-view />

    </v-main>

  </v-app>
</template>

<script>
// import screenfull from 'screenfull';
import NavigationDrawer from "@/components/NavigationDrawer.vue"
import { collectionStore } from "@/store/index.js";
import { authStore } from "@/store/authStore.js";

export default {
  name: 'App',
  components: {
    NavigationDrawer,
  },
  setup () {
    const auth_store = authStore()
    auth_store.checkConnection()
    const collection_store = collectionStore()
    collection_store.loadCollection()
    return {auth_store, collection_store}
  },
  watch: {
  },
  methods: {
    /*
    fullScreen () {
      if (screenfull.isEnabled) {
        screenfull.request();
      }
    },
    */
  },
}
</script>


<style>

.general-alert {
  width: calc(100vw - 255px);
  position: absolute;
  z-index: 100;
}

</style>