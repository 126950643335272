import { createRouter, createWebHistory } from 'vue-router'
import { authStore } from '@/store/authStore.js'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/explorer',
    name: 'explorer',
    component: () => import('../views/ExplorerView.vue')
  },
  {
    path: '/decklists',
    name: 'decklists',
    component: () => import('../views/DecklistsView.vue')
  },
  {
    path: '/meta',
    name: 'meta',
    component: () => import('../views/ConstructionView.vue')
  },
  {
    path: '/oracle',
    name: 'oracle',
    component: () => import('../views/ConstructionView.vue')
  },
  {
    path: '/lore',
    name: 'lore',
    component: () => import('../views/ConstructionView.vue')
  },
  {
    path: '/protour',
    name: 'protour',
    component: () => import('../views/ProtourView.vue')
  },
  {
    path: '/protour/:tab',
    name: 'protourTab',
    component: () => import('../views/ProtourView.vue')
  },
  {
    path: '/deckbuilder',
    name: 'deckbuilder',
    component: () => import('../views/DeckbuilderView.vue')
  },
  {
    path: '/deckbuilder/:did',
    name: 'deckbuilderLoad',
    component: () => import('../views/DeckbuilderView.vue')
  },
  {
    path: '/deckviewer/:did',
    name: 'deckviewer',
    component: () => import('../views/DeckviewerView.vue')
  },
  {
    path: '/deckbuilder/draftbuilder/:rid',
    name: 'draftbuilder',
    component: () => import('../views/DraftbuilderView.vue')
  },
  {
    path: '/drafter',
    name: 'drafter',
    component: () => import('../views/DrafterView.vue')
  },
  {
    path: '/drafter/:rid',
    name: 'drafterRoom',
    component: () => import('../views/DrafterRoomView.vue')
  },
  {
    path: '/sealed',
    name: 'sealed',
    component: () => import('../views/ConstructionView.vue')
  },
  {
    path: '/rotisserie',
    name: 'rotisserie',
    component: () => import('../views/ConstructionView.vue')
  },
  {
    path: '/roadmap',
    name: 'roadmap',
    component: () => import('../views/ConstructionView.vue')
  },
  {
    path: '/set_manager',
    name: 'set_manager',
    component: () => import('../views/SetManagerView.vue')
  },
  {
    path: '/set_manager/:code',
    name: 'set_manager_edit',
    component: () => import('../views/SetManagerEditView.vue')
  },
  {
    path: '/pool_manager',
    name: 'pool_manager',
    component: () => import('../views/ConstructionView.vue')
  },
  {
    path: '/img_database/',
    name: 'img_database',
    component: () => import('../views/ImageDatabaseView.vue')
  },
  {
    path: '/img_database/:path+',
    name: 'img_database_path',
    component: () => import('../views/ImageDatabaseView.vue')
  },
  {
    path: '/card_maker',
    name: 'card_maker',
    component: () => import('../views/CardMakerView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/* For Authentification Redirection */
router.beforeEach(async (to) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const auth = authStore()

  if (to.path !== '/login') {
    if (auth.logged === null) {
      var ok = await auth.checkConnection()
      if (ok === false) {
        auth.returnUrl = to.fullPath
        return '/login'
      }
      else {
        authStore.authToken = localStorage.authToken
        authStore.pseudo = localStorage.pseudo
        authStore.logged = true 
      }
    }
    else {
      if (authRequired && !auth.logged) {
        auth.returnUrl = to.fullPath
        return '/login'
      }
      else {
        authStore.authToken = localStorage.authToken
        authStore.pseudo = localStorage.pseudo
      }
    }
  }
})

export default router