import { defineStore } from 'pinia'
import axios from 'axios'
import { authStore } from './authStore'

export const collectionStore = defineStore('collectionStore', {
  state: () => ({ 
    API_PATH: "https://api.kagami.nyanpasu.moe",
    _collection: null,
    _decklists: null,
    alert: {
      on: false,
      type: "",
      msg: "",
    },
    cardQuality: 'Low',
    drawer: true,
    cardFocus: null,
    deckbuild: {
      id: null,
      name: "",
      tags: [],
      decklist: {
        "Créature": [],
        "Éphémère": [],
        "Rituel": [],
        "Enchantement": [],
        "Artefact": [],
        "Planeswalker": [],
        "Terrain": []
      },
      sidelist: [], 
      msg: null,
    },
    draftList: [],
    draftbuild: {
      id: null,
      name: "",
      tags: [],
      decklist: {
        "Créature": [],
        "Éphémère": [],
        "Rituel": [],
        "Enchantement": [],
        "Artefact": [],
        "Planeswalker": [],
        "Terrain": []
      },
      sidelist: [], 
      msg: null,
    },
  }),
  getters: {
    getDecksList: (state) => state._decklists,
    getDraftList: (state) => state.draftList, 
    collection: (state) => state._collection,
    getCardById: (state) => (cid) => {
      for (var card of state._collection) {
        if (card.cid === cid)
          return card
      }
      return null 
    },
    getCardsOfDeckList: (state) => {
      var dl = []
      var cardsByType = []
      for ([, cardsByType] of Object.entries(state.deckbuild.decklist)) {
        for (var card of cardsByType) {
          dl.push(card)
        }
      }
      return dl 
    },
    filterCollection: (state) => (filter) => {
      if (state._collection === null)
        return []
      return state._collection.filter( (card) => {
        var ok = true
        
        // Check card set
        if (filter.sets !== null && filter.sets.length > 0)
          ok = ok && filter.sets.includes(card.set)

        // Check card title
        if (filter.title !== null) {
          ok = ok && card.title.toUpperCase().includes(filter.title.toUpperCase())
        }

        // Check card type
        if (filter.type !== null && filter.type.length > 0) {
          ok = ok && filter.type.includes(card.type)
        }

        // Check card cmc 
        if (filter.cmc.equal !== null && filter.cmc.equal !== "") {
          ok = ok && card.cmc === filter.cmc.equal
        } 
        if (filter.cmc.greater !== null && filter.cmc.greater !== "") {
          ok = ok && card.cmc >= parseInt(filter.cmc.greater)
        } 
        if (filter.cmc.lesser !== null && filter.cmc.lesser !== "") {
          ok = ok && card.cmc <= parseInt(filter.cmc.lesser)
        } 

        // Check card rarity : 
        ok = ok && filter.rarity[card.rarity]

        // Check card color : 
        for (var color of card.colors.split("")) {
          ok = ok && filter.colors[color]
        }

        // Check card keywords : 
        if (filter.keywords !== null && filter.keywords.length !== "") {
          var filter_keywords = filter.keywords.split(' ')
          var card_keywords = card.keywords.split(',')
          for (var k of filter_keywords) {
            var ok2 = false 
            for (var kc of card_keywords) {
              if (kc.includes(k)) {
                ok2 = true 
                break 
              }
            }
            if (!ok2)
              break 
          }
          ok = ok && ok2
        }
        return ok
      })
    },
    filterDraftCollection: (state) => (filter) => {
      if (state.draftList === null)
        return []
      return state.draftList.filter( (card) => {
        var ok = true
        
        // Check card set
        if (filter.sets !== null && filter.sets.length > 0)
          ok = ok && filter.sets.includes(card.set)

        // Check card title
        if (filter.title !== null) {
          ok = ok && card.title.toUpperCase().includes(filter.title.toUpperCase())
        }

        // Check card type
        if (filter.type !== null && filter.type.length > 0) {
          ok = ok && filter.type.includes(card.type)
        }

        // Check card rarity : 
        ok = ok && filter.rarity[card.rarity]

        // Check card color : 
        for (var color of card.colors.split("")) {
          ok = ok && filter.colors[color]
        }

        // Check card keywords : 
        if (filter.keywords !== null && filter.keywords.length !== "") {
          var filter_keywords = filter.keywords.split(' ')
          var card_keywords = card.keywords.split(',')
          for (var k of filter_keywords) {
            var ok2 = false 
            for (var kc of card_keywords) {
              if (kc.includes(k)) {
                ok2 = true 
                break 
              }
            }
            if (!ok2)
              break 
          }
          ok = ok && ok2
        }
        return ok
      })
    }
  },
  actions: {
    setDraftList (uid) {
      console.log(uid)
    },
    loadCollection () {
      if (this._collection === null) {
        axios.get(this.API_PATH+"/cards/", {params: {"pseudo": this.pseudo}})
        .then (response => {
          this._collection = response.data
        }) 
        .catch(error => console.log(error.response.data))   
      }
    },
    loadDecklists () {
      if (this._decklists === null) { 
        this.reloadDecklists()
      }
    },
    reloadDecklists () {
      axios.get(this.API_PATH+"/deck/?pseudo="+authStore.pseudo)
      .then (response => {
        this._decklists = response.data
      }) 
      .catch(error => console.log(error.response.data))  
    },
    loadDeck (did) {
      axios.get(this.API_PATH+"/deck/?id=" + did)
      .then (response => {
        this.deckbuild = this.resetDeck()
        var deck = response.data
        this.deckbuild.name = deck.name 
        this.deckbuild.id = deck.id 
        var card = null
        for (card of deck.list) {
          this.addToDeck(card)
        }
        for (card of deck.side) {
          this.addToSide(card)
        }
      }) 
      .catch(error => console.log(error.response.data))   
    },
    async shareDeck (did) {
      let res = await axios.put(this.API_PATH+"/deck/", {
        pseudo: authStore.pseudo,
        authToken: authStore.authToken,
        deckId: did,
        shared: true,
      })
      .catch(error => {
        console.log(error.response.data)
        return false
      })
      if (res.status == 200)
        return true 
      return false 
    },
    async removeDeck (did) {
      let res = await axios.delete(this.API_PATH+"/deck/", {
        data: {
          pseudo: authStore.pseudo,
          authToken: authStore.authToken,
          id: did,  
        }
      })
      .catch(error => {
        console.log(error.response.data)
        return false
      })
      if (res.status == 200)
        return true 
      return false
    },
    resetDeck () {
      return {
        id: null,
        name: "",
        tags: [],
        decklist: {
          "Créature": [],
          "Éphémère": [],
          "Rituel": [],
          "Enchantement": [],
          "Artefact": [],
          "Planeswalker": [],
          "Terrain": []
        },
        sidelist: [], 
        msg: null,
      }
    },
    addToSide (cardToAdd) {
      var isNewCard = true
      for (var card of this.deckbuild.sidelist) {
          if (card.cid === cardToAdd.cid) {
            isNewCard = false
            card.sideQuantity++
            break 
          }
      }
      if (isNewCard) {
        cardToAdd.sideQuantity = 1
        this.deckbuild.sidelist.push(cardToAdd)
      }  
    },
    addToDeck (cardToAdd) {
      var isNewCard = true
      for (var card of this.deckbuild.decklist[cardToAdd.type]) {
          if (card.cid === cardToAdd.cid) {
            isNewCard = false
            card.quantity++
            break 
          }
      }
      if (isNewCard) {
        cardToAdd.quantity = 1
        this.deckbuild.decklist[cardToAdd.type].push(cardToAdd)
      }  
    },
    saveDecklist () {
      if (this.deckbuild.name === "") {
        this.deckbuild.msg = {type: "alert", msg: "Veuillez donner un nom à votre deck. "}
      }

      // Getting decklist
      var deckList = []
      for (var type in this.deckbuild.decklist) {
          var cards = this.deckbuild.decklist[type]
          for (var card of cards) {
              for (var i = 0 ; i < card.quantity ; i++)
                deckList.push(card.cid)
          }
      }
      deckList = deckList.join(',')

      if (deckList.length === 0) {
        this.deckbuild.msg =  {type: "alert", msg: "Votre liste est vide..."}
      }

      // Getting side
      var sideList = []
      for (card of this.deckbuild.sidelist) {
          for (i = 0 ; i < card.sideQuantity ; i++) {
            sideList.push(card.cid)
          }
      }
      sideList = sideList.join(',')

      if (this.deckbuild.id == null) {
        this.createDeck(this.deckbuild.name, deckList, sideList)
      }
      else {
        this.updateDeck(this.deckbuild.name, deckList, sideList)
      }
    },
    saveDraftlist () {

      this.draftbuild.name = "[Draft][PT LOS] " + authStore.pseudo

      // Getting decklist
      var deckList = []
      for (var type in this.draftbuild.decklist) {
          var cards = this.draftbuild.decklist[type]
          for (var card of cards) {
              for (var i = 0 ; i < card.quantity ; i++)
                deckList.push(card.cid)
          }
      }
      deckList = deckList.join(',')

      if (deckList.length === 0) {
        this.draftbuild.msg =  {type: "alert", msg: "Votre liste est vide..."}
      }

      // Getting side
      var sideList = []
      for (card of this.draftbuild.sidelist) {
          for (i = 0 ; i < card.sideQuantity ; i++) {
            sideList.push(card.cid)
          }
      }
      sideList = sideList.join(',')

      if (this.draftbuild.id == null) {
        this.createDeck(this.draftbuild.name, deckList, sideList)
      }
      else {
        this.updateDeck(this.draftbuild.name, deckList, sideList)
      }
    },
    createDeck (deckName, deckList, sideList) {
      axios.post(this.API_PATH+"/deck/", {
          pseudo: authStore.pseudo,
          authToken: authStore.authToken,
          deckName: deckName,
          deckList: deckList,
          sideList: sideList,
          tags: "",
          notes: "",
      })
      .then (response => {
        this.draftbuild.id = response.data.id 
        this.deckbuild.id = response.data.id
        this.deckbuild.msg = {type: "success", msg: "Votre deck « "+deckName+" » a été correctement sauvegardé."}
        this.reloadDecklists()
      })
      .catch(error => {
        this.deckbuild.msg = {type: "error", msg: error.data}
      })
    },
    updateDeck (deckName, deckList, sideList) {
        axios.put(this.API_PATH+"/deck/", {
            pseudo: authStore.pseudo,
            authToken: authStore.authToken,
            deckId: this.deckbuild.id,
            deckName: deckName,
            deckList: deckList,
            sideList: sideList,
            tags: "",
            notes: "",
        })
        .then ( () => {
          this.alert.type = 'success'
          this.alert.msg = "Votre deck « "+deckName+" » a été correctement sauvegardé."
          this.alert.on = true 
        })
        .catch(error => {
          this.alert.type = 'error'
          console.log(error)
          this.alert.msg = "Erreur : le deck n'a pas pu être sauvegardé. "
          this.alert.on = true 
        })
    },
    removeFromDraftList (cardToRemove) {
      var tmp = []
      var removed = false 
      for (var card of this.draftList) {
        if (!removed && card.cid == cardToRemove.cid) {
          removed = true 
          continue 
        }
        tmp.push(card)
      }
      this.draftList = tmp 
    },
    addToDraftDeck (cardToAdd) {
      console.log("Adding " + cardToAdd.title + " to decklist... ")
      var isNewCard = true
      for (var card of this.draftbuild.decklist[cardToAdd.type]) {
          if (card.cid === cardToAdd.cid) {
            console.log("Card " + cardToAdd.title + " already in decklist, increment quantity... ")
            isNewCard = false
            card.quantity++
            break 
          }
      }
      if (isNewCard) {
        console.log("Card " + cardToAdd.title + " is new, add card to deck... ")
        cardToAdd.quantity = 1
        this.draftbuild.decklist[cardToAdd.type].push(cardToAdd)
      }
    },
    resetDraftBuild () {
      this.draftbuild = {
        id: null,
        name: "",
        tags: [],
        decklist: {
          "Créature": [],
          "Éphémère": [],
          "Rituel": [],
          "Enchantement": [],
          "Artefact": [],
          "Planeswalker": [],
          "Terrain": []
        },
        sidelist: [], 
        msg: null,
      }
    }
  },
})
