<template>
    <v-navigation-drawer app v-model="store.drawer" bottom>
          <v-list nav density="compact" class="noselect">

            <v-list-subheader>Général</v-list-subheader>
            <v-list-item 
              active-color="primary" 
              v-for="item in navigationConf_general" 
                :key="'nav'+item.title"
                :title="item.title"
                :to="item.to"
                :value="item.to"
                :prepend-icon="item.icon"
                :disabled="item.disabled"
            ></v-list-item>

            <v-divider></v-divider>

            <v-list-subheader>Jouer</v-list-subheader>
            <v-list-item 
              active-color="primary" 
              v-for="item in navigationConf_play" 
                :key="'nav'+item.title"
                :title="item.title"
                :to="item.to"
                :value="item.to"
                :prepend-icon="item.icon"
                :disabled="item.disabled"
            ></v-list-item>

            <v-divider></v-divider>

            <v-list-subheader>Outils</v-list-subheader>
            <v-list-item 
              active-color="primary" 
              v-for="item in navigationConf_tools" 
                :key="'nav'+item.title"
                :title="item.title"
                :to="item.to"
                :value="item.to"
                :prepend-icon="item.icon"
                :disabled="item.disabled"
            ></v-list-item>
            
          </v-list>
    </v-navigation-drawer>
  </template>
  
  <script>
  import { collectionStore } from "@/store";
  export default {
    setup () {
      const store = collectionStore()
      return {store}
    },
    data: () => ({
      navigationConf_general: [
        {
          "title": "Home",
          "to": "/",
          "icon": "mdi-home",
        },
        {
          "title": "Explorer",
          "to": "/explorer",
          "icon": "mdi-cards",
        },
        {
          "title": "Deck Listes",
          "to": "/decklists",
          "icon": "mdi-cards-variant",
        },
        {
          "title": "Pro Tours",
          "to": "/protour",
          "icon": "mdi-trophy",
          "disabled": false,
        },
        {
          "title": "Méta",
          "to": "/meta",
          "icon": "mdi-chart-bar",
          "disabled": true,
        },
        {
          "title": "Oracle",
          "to": "/oracle",
          "icon": "mdi-library",
          "disabled": true,
        },
        {
          "title": "Lore",
          "to": "/lore",
          "icon": "mdi-book",
          "disabled": true,
        },
      ],
      navigationConf_play: [
        {
          "title": "Deck Builder",
          "to": "/deckbuilder",
          "icon": "mdi-view-dashboard",
        },
        {
          "title": "Drafter",
          "to": "/drafter",
          "icon": "mdi-cards-outline",
          "disabled": true,
        },
        {
          "title": "Paquet Scellés",
          "to": "/sealed",
          "icon": "mdi-animation",
          "disabled": true,
        },
        {
          "title": "Rôtisserie",
          "to": "/rotisserie",
          "icon": "mdi-food-drumstick",
          "disabled": true,
        },
      ],
      navigationConf_tools: [
        {
          "title": "Road Map",
          "to": "/roadmap",
          "icon": "mdi-calendar-check-outline",
          "disabled": true,
        },
        {
          "title": "Set Manager",
          "to": "/set_manager",
          "icon": "mdi-table-of-contents",
        },
        {
          "title": "Pool Manager",
          "to": "/pool_manager",
          "icon": "mdi-set-none",
          "disabled": true,
        },
        {
          "title": "Image Database",
          "to": "/img_database",
          "icon": "mdi-view-day",
        },
        {
          "title": "Card Maker",
          "to": "/card_maker",
          "icon": "mdi-hammer-screwdriver",
          "disabled": true,
        },
      ]
    }),
  }
  </script>

<style>
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
</style>