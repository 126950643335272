import { defineStore } from 'pinia'
import axios from 'axios'
import router from '@/router/index'

export const authStore = defineStore('authStore', {
  state: () => ({ 
    logged: null,
    pseudo: "",
    authToken: "",
    profile: {
      icon: "",
    }
  }),
  getters: {
  },
  actions: {
    logout () {
      this.logged = false
      localStorage.clear()
      location.reload()
    },
    async checkConnection () {
      if (localStorage.authToken === 'undefined') {
        this.logged = false
        return false 
      }
      try {
        if (localStorage.authToken && localStorage.pseudo) {
          axios.post("https://api.kagami.nyanpasu.moe/user/", {
            pseudo: localStorage.pseudo,
            authToken: localStorage.authToken,
          })
          .then ((response) => {
            try {
              if (response.data.connection === "ok") {
                this.authToken = localStorage.authToken
                this.pseudo = localStorage.pseudo
                this.logged = true 
                return true 
              }
              this.logout()
              return false 
            }
            catch (e) {
              this.logout()
              return false 
            }
          })
        }
        else {
          this.logged = false 
          return false
        }
        return true 
    }
      catch (e) {
        console.log(e)
      }
    },
    logUser (pseudo, password) {
      console.log("Logging...")
      axios.post("https://api.kagami.nyanpasu.moe/user/", {
        pseudo: pseudo,
        password: password,
      })
      .then (response => {
        try {
          console.log(response.data)
          this.pseudo = pseudo
          console.log("Pseudo set ("+this.pseudo+")")
          this.authToken = response.data.authToken
          this.logged = true  
          localStorage.pseudo = this.pseudo
          localStorage.authToken = this.authToken 
          router.push('/')
        }
        catch (e) {
          this.error = "Erreur - Identifiants Incorrects"
        }
      })
      .catch(error => {
        console.log("Erreur : " + error.response.data)
      })
    }
  }
})
